var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row offers mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h2', {
    staticClass: "title text-center pt-1 mt-1"
  }, [_vm._v("INVOICE")]), _c('div', {
    staticClass: "col-md-12 pt-2"
  }, [_c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Laporan total penjualan dari semua offers (digital, eperpus) dan siap untuk di cairkan. Laporan dikelompokan berdasarkan vendor, offer type & periode. ")]), _c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Invoice")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query_search,
      expression: "query_search"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "INV-SGL-251-20230430",
      "onfocus": "this.placeholder = 'type invoice number...'"
    },
    domProps: {
      "value": _vm.query_search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.query_search = $event.target.value;
      }, function ($event) {
        return _vm.actionGetReportInvoice();
      }]
    }
  })])])], 1), _c('div', {
    staticClass: "col-md-12 pt-2"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Periode")]), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('Calendar', {
    attrs: {
      "selectionMode": "range",
      "view": "month",
      "manualInput": false,
      "dateFormat": "mm/yy"
    },
    model: {
      value: _vm.dates,
      callback: function ($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1)])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Offer Type")]), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('b-form-select', {
    staticClass: "flex-grow",
    attrs: {
      "id": "searchOfferMode",
      "track-by": "value",
      "options": _vm.offerTypeOptions,
      "required": ""
    },
    on: {
      "change": _vm.setOfferType
    },
    model: {
      value: _vm.offerType,
      callback: function ($$v) {
        _vm.offerType = $$v;
      },
      expression: "offerType"
    }
  })], 1)]), _c('div', {
    staticClass: "form-group row"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Publisher")]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "disabled": _vm.userData.role_id === 5,
      "placeholder": "Type Publisher",
      "open-direction": "bottom",
      "options": _vm.publisherSearchOptions,
      "loading": _vm.isPublisherSearch,
      "multiple": true,
      "searchable": true,
      "close-on-select": false
    },
    on: {
      "search-change": _vm.publisherFind
    },
    model: {
      value: _vm.publisher,
      callback: function ($$v) {
        _vm.publisher = $$v;
      },
      expression: "publisher"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-2"
  }, [_c('button', {
    staticClass: "btn btn-block btn-primary",
    on: {
      "click": _vm.actionGetReportInvoice
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])]), _c('b-spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoadingSearchByInvoiceNumber || _vm.isLoadingSearchByDisbursementPeriod,
      expression: "\n              isLoadingSearchByInvoiceNumber ||\n                isLoadingSearchByDisbursementPeriod\n            "
    }],
    staticClass: "ml-2",
    attrs: {
      "variant": "dark",
      "label": "Loading .."
    }
  })], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row justify-content-end pb-1"
  }, [_c('span', [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "id": "refresh-list"
    },
    on: {
      "click": _vm.actionGetReportInvoice
    }
  }, [_c('em', {
    staticClass: "fa fa-refresh"
  })])]), _c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": "refresh-list"
    }
  }, [_vm._v("Count ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.totalRows))])]), _c('b-table', {
    staticClass: "mt-2",
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "responsive": "sm",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }, {
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(vendor_name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.vendor_name) + " ")];
      }
    }, {
      key: "cell(number_invoice)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.number_invoice) + " ")];
      }
    }, {
      key: "cell(disbursement_period)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPeriod(row.item.disbursement_period)) + " ")];
      }
    }, {
      key: "cell(disbursement)",
      fn: function (row) {
        return [!row.item.disbursement ? _c('div', [_c('i', [_vm._v(" In callculated")])]) : _c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(row.item.disbursement, "IDR")) + " ")])];
      }
    }, {
      key: "cell(offer_type)",
      fn: function (row) {
        var _row$item, _row$item2, _row$item3, _row$item4, _row$item5, _row$item6, _row$item7, _row$item8;
        return [((_row$item = row.item) === null || _row$item === void 0 ? void 0 : _row$item.offer_type) == 'single' ? _c('div', [_c('span', {
          staticClass: "badge badge-primary p-1"
        }, [_c('i', {
          staticClass: "fa fa-tag"
        }), _vm._v(" " + _vm._s((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.offer_type) + " ")])]) : _vm._e(), ((_row$item3 = row.item) === null || _row$item3 === void 0 ? void 0 : _row$item3.offer_type) == 'bundle' ? _c('div', [_c('span', {
          staticClass: "badge badge-yellow p-1"
        }, [_c('i', {
          staticClass: "fa fa-tag"
        }), _vm._v(" " + _vm._s((_row$item4 = row.item) === null || _row$item4 === void 0 ? void 0 : _row$item4.offer_type) + " ")])]) : _vm._e(), ((_row$item5 = row.item) === null || _row$item5 === void 0 ? void 0 : _row$item5.offer_type) == 'subscription' ? _c('div', [_c('span', {
          staticClass: "badge badge-danger"
        }, [_c('i', {
          staticClass: "fa fa-tag"
        }), _vm._v(" " + _vm._s((_row$item6 = row.item) === null || _row$item6 === void 0 ? void 0 : _row$item6.offer_type) + " ")])]) : _vm._e(), ((_row$item7 = row.item) === null || _row$item7 === void 0 ? void 0 : _row$item7.offer_type) == 'buffet' ? _c('div', [_c('span', {
          staticClass: "badge badge-purple p-1"
        }, [_c('i', {
          staticClass: "fa fa-diamond"
        }), _vm._v(" " + _vm._s((_row$item8 = row.item) === null || _row$item8 === void 0 ? void 0 : _row$item8.offer_type) + " ")])]) : _vm._e()];
      }
    }, {
      key: "cell(action_download)",
      fn: function (row) {
        return [row.item.alloweddownload ? _c('b-button', {
          attrs: {
            "id": ("invoice-download-" + (row.item.number_invoice)),
            "variant": row.item.is_active ? 'outline-primary' : 'outline-danger',
            "disabled": _vm.buttonLoading[row.index] || _vm.buttonDownloaded[row.index] || !row.item.is_active
          },
          on: {
            "click": function ($event) {
              return _vm.downloadInvoice({
                offer_type: row.item.offer_type,
                vendor_id: row.item.vendor_id,
                period: row.item.disbursement_period,
                index: row.index,
                number_invoice: row.item.number_invoice
              });
            }
          }
        }, [row.item.is_active && !_vm.buttonLoading[row.index] && !_vm.buttonDownloaded[row.index] ? _c('div', [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Download ")]) : !row.item.is_active && row.item.alloweddownload ? _c('div', [_c('i', {
          staticClass: "fa fa-ban"
        }), _vm._v(" Unavailable ")]) : _vm.buttonLoading[row.index] ? _c('div', [_c('i', {
          staticClass: "fa fa-spinner fa-spin"
        }), _c('span', {
          staticClass: "d-inline-block ml-2",
          attrs: {
            "tabindex": "0",
            "data-toggle": "tooltip",
            "title": _vm.tooltipText
          }
        }, [_vm._v(" Loading .. ")])]) : _vm.buttonDownloaded[row.index] ? _c('div', [_c('i', {
          staticClass: "fa fa-check"
        }), _c('span', {
          staticClass: "d-inline-block ml-2",
          attrs: {
            "tabindex": "0",
            "data-toggle": "tooltip",
            "title": _vm.tooltipText
          }
        }, [_vm._v(" Processed ")])]) : _c('div')]) : _vm._e(), !row.item.alloweddownload ? _c('div', [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Vendor Id Issue ")]) : _vm._e()];
      }
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1 mt-2 pt-4"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('div', [_c('blockquote', {
    staticClass: "blockquote text-center"
  }, [_c('p', {
    staticClass: "mb-0 font-sm"
  }, [_vm._v(" This Page for preview only, please download to get Detail Invoice detail invoice will send to your email after download ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Gramedia Digital "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Finance Report ")])])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }